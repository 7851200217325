{
  "name": "bia-portal",
  "version": "1.0.8.136-1",
  "license": "MIT",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/akveo/ngx-admin.git"
  },
  "bugs": {
    "url": "https://github.com/akveo/ngx-admin/issues"
  },
  "scripts": {
    "ng": "ng",
    "conventional-changelog": "conventional-changelog",
    "start": "ng serve",
    "start:proxy": "ng serve --configuration proxy",
    "start:local": "ng serve --configuration local",
    "start:localIIS": "ng serve --configuration localIIS",
    "build": "ng build",
    "build:prod": "npm run build -- --configuration production --aot",
    "test": "ng test",
    "test:coverage": "rimraf coverage && npm run test -- --code-coverage",
    "lint": "ng lint",
    "lint:fix": "ng lint bia-portal --fix",
    "lint:styles": "stylelint ./src/**/*.scss",
    "lint:ci": "npm run lint && npm run lint:styles",
    "pree2e": "webdriver-manager update --standalone false --gecko false",
    "e2e": "ng e2e",
    "docs": "compodoc -p tsconfig.app.json -d docs",
    "docs:serve": "compodoc -p tsconfig.app.json -d docs -s",
    "prepush_disabled": "npm run lint:ci",
    "release:changelog": "npm run conventional-changelog -- -p angular -i CHANGELOG.md -s"
  },
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.1.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/localize": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@kolkov/angular-editor": "^3.0.0-beta.0",
    "@nebular/auth": "13.0.0",
    "@nebular/date-fns": "^13.0.0",
    "@nebular/eva-icons": "^13.0.0",
    "@nebular/moment": "^13.0.0",
    "@nebular/security": "^13.0.0",
    "@nebular/theme": "^13.0.0",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ng-idle/core": "^14.0.0",
    "@ng-select/ng-select": "^12.0.7",
    "@ngneat/until-destroy": "^10.0.0",
    "@popperjs/core": "^2.11.6",
    "bootstrap": "4.6.2",
    "chart.js": "^4.4.2",
    "classlist.js": "1.1.20150312",
    "core-js": "^3.35.0",
    "date-fns": "^2.30.0",
    "eva-icons": "^1.1.3",
    "file-saver": "^2.0.5",
    "intl": "1.2.5",
    "intl-tel-input": "^23.0.12",
    "ionicons": "2.0.1",
    "libphonenumber-js": "^1.11.4",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "nebular-icons": "1.1.0",
    "ng2-charts": "^6.0.0",
    "ngx-avatars": "^1.7.0",
    "ngx-drag-drop": "^17.0.0",
    "ngx-logger": "^5.0.12",
    "normalize.css": "6.0.0",
    "roboto-fontface": "0.8.0",
    "rxjs": "^7.4.0",
    "style-loader": "^1.3.0",
    "tslib": "^2.6.2",
    "typeface-exo": "1.1.13",
    "web-animations-js": "^2.3.2",
    "zone.js": "^0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular-eslint/builder": "17.2.0",
    "@angular-eslint/eslint-plugin": "17.2.0",
    "@angular-eslint/eslint-plugin-template": "17.2.0",
    "@angular-eslint/schematics": "17.2.0",
    "@angular-eslint/template-parser": "17.2.0",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@angular/language-service": "17.1.0",
    "@compodoc/compodoc": "^1.1.14",
    "@fortawesome/fontawesome-free": "^5.2.0",
    "@trivago/prettier-plugin-sort-imports": "^4.3.0",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "^5.43.0",
    "@typescript-eslint/parser": "^5.43.0",
    "codelyzer": "^6.0.2",
    "conventional-changelog-cli": "^2.1.1",
    "eslint": "^8.56.0",
    "husky": "0.13.3",
    "jasmine-core": "~5.1.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "npm-run-all": "4.0.2",
    "prettier": "^3.3.3",
    "rimraf": "2.6.1",
    "stylelint": "^16.1.0",
    "ts-node": "3.2.2",
    "typescript": "^5.3.3"
  }
}
